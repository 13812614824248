// import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HasuraServices } from '../application/protocols/services/api_services';
import { useAppContext } from '../application/stores/AppContext';
import { useChatContext } from '../application/stores/ChatContext';
import { useMarketContext } from '../application/stores/MarketContext';
import ChatbotResponse from '../components/ChatbotResponse';
import CustomButton from '../components/CustomButton';
import CustomSearchField from '../components/CustomSearchField';
import CustomSelectedImage from '../components/CustomSelectedImage';
import LoadingButton from '../components/LoadingButton';
import DropZone from '../components/containers/DropZone';
// import IOSSwitch from '../components/widgets/PowerButton';

const ChatBot = () => {
  const {
    query,
    setQuery,
    setStoreModelName,
    handleRestart,
    restarting,
    handleSearch,
    oldResponses,
    chatLoading,
    images,
    setImages,
    imageRef,
    imageChange,
    handleUpload,
    isFavouritePage,
    setIsFavouritePage,
    setIsChatPage,
    chatHistoryLoading,
  } = useChatContext();
  const { bots } = useMarketContext();
  const { setLabel } = useAppContext();
  const { t } = useTranslation('global');
  const sendRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState(0);

  const {
    setModal,
    setIsModalOpen,
    setModalWidth,
    modalHeight,
    setModalHeight,
    windowWidth,
    windowHeight,
    headerImage,
  } = useAppContext();

  const routes = useParams();
  //Converting name from route eg. excel_vba to Excel Vba
  const name = routes.name;

  useEffect(() => {
    if (routes[Object.keys(routes)[0]] == 'favorite') {
      setIsFavouritePage(true);
    } else {
      setIsFavouritePage(false);
    }

    if (routes[Object.keys(routes)[0]] == 'market') {
      setIsChatPage(true);
    } else {
      setIsChatPage(false);
    }
    // setResponse("How can we help you?");
  }, [routes]);

  useEffect(() => {
    const selectedAssistant = bots?.filter(
      (bot) =>
        bot?.redirect_link.substring(8).toLowerCase() == name.toLowerCase()
    );
    if (selectedAssistant && selectedAssistant.length > 0) {
      setLabel(
        `${selectedAssistant[0]?.purpose_category
          .split('')
          .map((value, index) => (index == 0 ? value.toUpperCase() : value))
          .join('')}\n\n${selectedAssistant[0]?.display_name}`
      );
    }
    let derivedStoreModalName = String(routes.name).split('-').join('_');
    if (!headerImage?.includes('data:image/png;base64')) {
      const query = `query CardInstruction($card_id:String!){
      cards(where: {card_id:{_eq:$card_id}}){
        description
      }
      user_card_parameter(where:{card_id:{_eq:$card_id}}){
        status
      }
    }`;
      const variables = {
        card_id: derivedStoreModalName,
      };

      HasuraServices.instance.query(query, variables).then(async (res) => {
        if (!res.msg) {
          return;
        }
        if (res.msg.cards && res.msg.cards[0]?.description) {
          if (res.msg.user_card_parameter.length < 1) {
            const insertUserParameterQuery = `mutation InsertUserCardParameter($card_id:String!){
            insert_user_card_parameter_one(object:{card_id:$card_id}){
              card_id
            }
          }`;
            console.log(
              await HasuraServices.instance.query(
                insertUserParameterQuery,
                variables
              )
            );
            res.msg.user_card_parameter.push({ status: 'new' });
          }
          if (headerImage && res.msg.user_card_parameter[0].status == 'new') {
            await Swal.fire({
              html: res.msg.cards[0].description.info[0].text,
              confirmButtonColor: '#d33',
              confirmButtonAriaLabel: `Don't show again`,
              showCancelButton: true,
              cancelButtonColor: '#521E95',
              confirmButtonText: `Don't show again`,
              cancelButtonText: `Hide`,
              showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
              },
              hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
              },
              imageUrl: headerImage,
              imageWidth: 200,
              footer:
                'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
            }).then((result) => {
              if (result.isConfirmed) {
                const updateMutation = `mutation UpdateUserCardParameter($card_id:String!){
                update_user_card_parameter(where:{card_id:{_eq:$card_id}},_set:{status:old}){
                  affected_rows
                }
              }`;
                HasuraServices.instance
                  .query(updateMutation, variables)
                  .then(() => {
                    Swal.fire({
                      title: 'Got it!',
                      text: 'We will not show you instructions for this card again.',
                      icon: 'success',
                      timer: 2000,
                      footer:
                        'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
                    });
                  });
              }
            });
          }
        }
      });
    }
    setStoreModelName(derivedStoreModalName);
  }, [headerImage, bots]);

  const handleSelectedImageClick = (e) => {
    e.preventDefault();
    setModalWidth;
    setIsModalOpen(true);
    setModalWidth(0.7 * windowWidth);
    setModalHeight(0.8 * windowHeight);
    setModal(
      <img
        src={e.target.src}
        style={{ maxHeight: modalHeight - 70 }}
        className="w-full max-w-full h-full max-h-full object-contain flex rounded-b-[26px]"
      />
    );
  };

  const handleKeyDown = (event) => {
    // For Windows/Linux
    if (event.shiftKey && event.key == 'Enter') {
      // Set the cursor position after the inserted newline
      setQuery(event.target.value);
      // textarea.selectionStart = textarea.selectionEnd = start + 1;
    } else if (event.key == 'Enter') {
      if (
        routes.name.includes('programming') ||
        routes.name.includes('python')
      ) {
        setQuery(event.target.value);
      } else if (sendRef) {
        if (sendRef.current) {
          event.preventDefault();
          sendRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    if (!query.trim()) {
      //if query only contains a enter, delete the query
      setQuery('');
    }
    let count = 0;
    for (let i = 0; i < query.length; i++) {
      if (query[i] == '\n') {
        count++;
      }
    }
    setTextareaHeight(`${27 * (count + 1)}px`);
  }, [query]);

  return (
    <DropZone id={'dropzone'} className="w-full h-full ">
      <div className="bg-secondbackground md:p-4 pb-0 pt-0 flex max-w-full h-full min-h-[83vh] max-h-[83vh]">
        <div className="flex flex-col justify-start items-center mx-1 min-h-[83vh] max-h-[83vh] w-full max-w-full  bg-secondbackground rounded-lg p-4 py-12 md:p-16 md:pb-12">
          <div className="flex flex-row justify-between items-start -mt-8 md:-mt-12 md:-ml-24  w-full  rounded-lg">
            <div className="flex flex-row">
              {restarting && (
                <LoadingButton
                  className={'h-6 max-md:h-1 max-md:mb-4 max-md:mt-2'}
                  bgColor="#88001B"
                />
              )}
              {!restarting && (
                <CustomButton
                  label={t('buttons.restart')}
                  onClick={handleRestart}
                  style="bg-secondbackground border-warning text-warning text-[8px]  md:text-sm  border-[1px]  w-full  hover:border-2 font-bold  px-2 text-center rounded-md hover:bg-warning hover:text-white focus:ring focus:ring-blue-200 "
                />
              )}
            </div>
            {/* <div className="flex items-center justify-center max-[400px]:pr-[90px]">
              <div className="font-medium -mr-3 max-[400px]:-mr-2 text-md">
                Power
              </div>
              <FormControlLabel
                sx={{ marginRight: -12, fontFamily: 'inherit' }}
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                checked={checked}
                onChange={() => setChecked(!checked)}
                labelPlacement="start"
              />
            </div> */}
          </div>
          <div className="w-full flex items-left -mb-3">
            <span className="text-textbackground text-sm -mb-2 opacity-55">
              Supported documents: 'Image, PDF'
            </span>
          </div>
          <form
            onSubmit={handleSearch}
            className="flex max-md:flex-col justify-start md:mt-6 items-end mx-1  w-full  rounded-lg pb-2"
          >
            <CustomSearchField
              textarea={true}
              displaySearchIcon={false}
              hintText={t('chatbot.search_hint')}
              value={query}
              textareaHeight={textareaHeight}
              onKeyDown={handleKeyDown}
              onChange={(ev) => setQuery(ev.target.value)}
              className="flex w-full max-w-full"
            />
            <div className="max-w-full flex">
              <div className="flex flex-row ml-4 md:mb-6">
                <input
                  type="file"
                  name="image"
                  // supports png,pdf,xlsx,csv, jpg and jpeg
                  accept="image/png,image/jpeg,application/pdf"
                  onChange={imageChange}
                  ref={imageRef}
                  className=""
                  style={{ display: 'none' }}
                />
                {!chatLoading && (
                  <CustomButton
                    type={'button'}
                    style={
                      'bg-primary w-full h-10 max-md:h-6 text-sm text-mainbackground font-bold md:py-2 px-4 md:px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
                    }
                    label={t('buttons.upload')}
                    onClick={handleUpload}
                  />
                )}
              </div>

              <div className="flex flex-row ml-2 md:mb-6">
                {(chatLoading || restarting) && (
                  <LoadingButton className={'h-10 max-md:h-6'} />
                )}
                {!chatLoading && !restarting && (
                  <CustomButton
                    ref={sendRef}
                    type={'submit'}
                    label={t('buttons.send')}
                    style={
                      'bg-primary w-full h-10 max-md:h-6 text-sm text-mainbackground font-bold md:py-2 px-4 md:px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
                    }
                  />
                )}
              </div>
            </div>
          </form>
          <div className="max-h-full w-full max-w-full max-md:pb-12 md:pr-4 overflow-y-scroll">
            <div
              className={`flex flex-wrap gap-6 pb-8 w-full ${
                images.length < 1 ? 'hidden' : ''
              }`}
            >
              {images.map((file, index) => (
                <CustomSelectedImage
                  key={'chatbot_page_files_' + index}
                  file={file}
                  images={images}
                  setImages={setImages}
                  index={index}
                  handleSelectedImageClick={handleSelectedImageClick}
                />
              ))}
            </div>
            {oldResponses &&
              oldResponses.map((oldResponse) => (
                <ChatbotResponse
                  keyID={oldResponse.id}
                  images={oldResponse.images}
                  query={oldResponse.query}
                  isFavouritePage={isFavouritePage}
                  response={oldResponse.response}
                  style={{ marginTop: windowWidth > 768 ? 12 : 4 }}
                  className={'max-w-full'}
                  favourite={oldResponse.fav}
                  chatDate={oldResponse.chatDate}
                  cardName={oldResponse.cardName}
                />
              ))}
            {oldResponses && oldResponses.length < 1 && (
              <ChatbotResponse
                keyID={''}
                images={''}
                query={''}
                isFavouritePage={false}
                response={'how can I help you?'}
                style={{ marginTop: windowWidth > 768 ? 12 : 4 }}
                className={'max-w-full'}
                favourite={false}
              />
            )}
            {chatHistoryLoading && (
              <div className="flex justify-center items-center">
                <LoadingButton
                  className={'max-w-12 h-10 max-md:h-6 bg-transparent mt-8'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </DropZone>
  );
};

export default ChatBot;
