import axios from 'axios';
import { blobToURL, fromURL } from 'image-resize-compress';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { getUserIdFromToken } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../storages/storage_services';
import { useAppContext } from './AppContext';
import { useChatSessionContext } from './ChatSessionContext';
import { useProfileContext } from './ProfileContext';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { checked, base_url, label } = useAppContext();
  const [chatLoading, setChatLoading] = useState(false);
  const [isFavouritePage, setIsFavouritePage] = useState(false);
  const [isChatPage, setIsChatPage] = useState(false);
  const [restartTimeout, setRestartTimeout] = useState(null);
  const [restarting, setRestarting] = useState(false);
  const { t } = useTranslation('global');
  const [query, setQuery] = useState('');
  const [modelName, setModalName] = useState('gemini-1.0-pro');
  const [storeModelName, setStoreModelName] = useState('excel_vba');
  const [oldResponses, setOldResponses] = useState([
    { query: '', response: t('chatbot.initial_response'), images: [] },
  ]);
  const [oldFavouriteResponses, setOldFavouriteResponses] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [images, setImages] = useState([]);
  const [copiedContent, setCopiedContent] = useState('');
  const imageRef = useRef(null);
  const { sendMessage, receivedMessage, isWebsocketON } =
    useChatSessionContext();
  const { profile } = useProfileContext();
  const [loadedImages, setLoadedImages] = useState({});
  const [chatHistoryLoading, setChatHistoryLoading] = useState(true);
  const [limit, setLimit] = useState(2);
  const [offset, setOffset] = useState(0);
  const [importDebounceTimeout, setImportDebounceTimeout] = useState(null);

  const backend_file_url = base_url + 'image/';
  const clearData = () => {
    setChatLoading(false);
    setRestarting(false);
    setQuery('');
    setModalName('');
    setStoreModelName('');
    setOldResponses([
      { query: '', response: t('chatbot.initial_response'), images: [] },
    ]);
    setImages([]);
    setCopiedContent([]);
    setLoadedImages({});
    setOldFavouriteResponses([]);
  };

  const handleBlob = async (sourceUrl) => {
    // quality value for webp and jpeg formats.
    const quality = 80;
    // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
    const width = 500;
    // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
    const height = 0;
    // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
    const format = 'jpeg';

    // will output the converted blob file
    // will generate a url to the converted file
    const blob = await fromURL(sourceUrl, quality, width, height, format);
    const url = await blobToURL(blob);
    return url;
  };

  async function _getBase64FromRemoteUrl(url) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        responseType: 'blob',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const reader = new FileReader();
      const base64Data = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      return base64Data;
    } catch (error) {
      console.error('Error:', error);
      return null; // Or throw an error if needed
    }
  }

  const copyToClipboard = (responseToCopy) => {
    window.navigator.clipboard.writeText(responseToCopy);
    setCopiedContent(responseToCopy);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // if (response != t('chatbot.initial_response')) {

    // }
    if (query || images.length > 0) {
      setChatLoading(true);
      setOldResponses((prev) => {
        const returningArray = prev.filter(
          (chatBlock) => chatBlock.query || chatBlock.images.length > 0
        );
        setQuery('');
        return [
          {
            query: query ?? '.',
            response: '',
            images: images.map((img) => {
              return { image: img };
            }),
            fav: false,
            chatDate: new Date().toISOString(),
            cardName: storeModelName.split('-').join('_'),
          },
          ...returningArray,
        ];
      });
      sendChatQuery();
    }
  };

  const handleMoreDetails = async (pvtQuery, files) => {
    if (pvtQuery || files.length > 0) {
      setChatLoading(true);
      setOldResponses((prev) => {
        const returningArray = prev.filter(
          (chatBlock) => chatBlock.query || chatBlock.images.length > 0
        );
        return [
          {
            query: pvtQuery ?? '.',
            response: '',
            images: files.map((file) => {
              return {
                image: file.image.split('/')[file.image.split('/').length - 1],
              };
            }),
          },
          ...returningArray,
        ];
      });
      // console.log(files);

      // let extractedFiles = [];
      // for (let i = 0; i < files.length; i++) {
      //   if (typeof files[i] == String) {
      //     if (
      //       String(files[i]).includes('https://') ||
      //       String(files[i]).includes('http://')
      //     ) {
      //       extractedFiles.push(
      //         await getBase64FromRemoteUrl(
      //           `http://localhost:8000/image/${LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)}/${profile.user.id}/${files[i].split('/')[files[i].split('/').length - 1]}`
      //         )
      //       );
      //     } else {
      //       extractedFiles = files;
      //     }
      //   } else {
      //     if (
      //       String(files[i].image).includes('https://') ||
      //       String(files[i].image).includes('http://')
      //     ) {
      //       extractedFiles.push(
      //         await getBase64FromRemoteUrl(
      //           `http://localhost:8000/image/${LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)}/${profile.user.id}/${files[i].image.split('/')[files[i].image.split('/').length - 1]}`
      //         )
      //       );
      //     } else {
      //       for (let i = 0; i < files.length; i++) {
      //         extractedFiles.push(files[i].image);
      //       }
      //     }
      //   }
      // }
      // const compressedExtractedFiles = [];
      // for (let i = 0; i < extractedFiles.length; i++) {
      //   if (String(extractedFiles[i]).includes(['jpg', 'jpeg', 'png'])) {
      //     const img = await handleBlob(extractedFiles[i]);
      //     compressedExtractedFiles.push(img);
      //   } else {
      //     compressedExtractedFiles.push(extractedFiles[i]);
      //   }
      // }
      if (pvtQuery || files.length > 0) {
        let _doc;
        let args;
        const modifiedQuery = pvtQuery
          .split('\n')
          .join('\\n')
          .split('"')
          .join("'");
        //build appropriate doc and args according to the type of input
        if (files.length > 0) {
          args = {
            query: String(modifiedQuery),
            documents: files.map(
              (file) => file.image.split('/')[file.image.split('/').length - 1]
            ),
            request_type: checked ? 'power_more_details' : 'more_details',

            store_model_name: storeModelName,
          };
        } else {
          args = {
            query: String(modifiedQuery),
            request_type: checked ? 'power_more_details' : 'more_details',
            store_model_name: storeModelName,
          };
        }
        //Send message to the websocket connection
        await sendMessage({ args: args });

        clearTimeout(debounceTimeout);
        setDebounceTimeout(setTimeout(() => setChatLoading(false), 120000));
      }
    }
  };

  const handleRestart = (e) => {
    e.preventDefault();
    setImages([]);
    setOldResponses([
      { query: '', response: t('chatbot.initial_response'), images: [] },
    ]);
    setQuery('');
    setRestarting(true);
    sendMessage({
      args: {
        query: 'restart',
        request_type: 'normal',
        store_model_name: storeModelName,
      },
    });
    setRestartTimeout(
      setTimeout(() => {
        setRestarting(false);
      }, 15000)
    );
  };

  const handleUpload = (event) => {
    event.preventDefault();
    imageRef.current?.click();
  };

  const imageChangeFromDropZone = useCallback(
    (files) => {
      //restart button is getting clicked automatically when dropping images
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            if (
              reader.result.startsWith('data:image/') ||
              (reader.result.startsWith('data:application/') &&
                !reader.result.startsWith(
                  'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ))
            ) {
              let file_path = await uploadDocument(reader.result, file.name);
              const file_temp = convertUrl(file_path);
              setImages((prev) => {
                let imgs = [];
                // console.log(reader.result);
                if (prev.length < 10) {
                  for (let i = 0; i < prev.length; i++) {
                    imgs.push(prev[i]);
                  }
                  imgs.push(file_temp);
                } else {
                  const tempImages = prev.filter((image, index) => index < 9);
                  imgs.push(file_temp);
                  for (let i = 0; i < tempImages.length; i++) {
                    imgs.push(tempImages[i]);
                  }
                }
                return imgs;
              });
            }
          };
          reader.readAsDataURL(file);
        }
      }
    },
    [images]
  );

  function convertUrl(url) {
    // Replace %20 with spaces
    const decodedUrl = decodeURIComponent(url);

    // Handle other encoded characters if needed
    // For example:
    // const finalUrl = decodedUrl.replace(/%2F/g, '/').replace(/%3A/g, ':');

    return decodedUrl;
  }

  const handlePaste = useCallback(
    (event) => {
      const clipboardItems = event.clipboardData.items;

      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        const extension =
          item.type ==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'application/xlsx'
            : item.type;
        if (
          item.type.startsWith('image/') ||
          (item.type.startsWith('application/') &&
            !extension.startsWith('application/xlsx'))
        ) {
          event.preventDefault();
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = async () => {
            let filePath = await uploadDocument(
              reader.result,
              `${profile.first_name} ${profile.last_name}.${extension.split('/')[1]}`
            );
            if (filePath) {
              const file_temp = convertUrl(filePath);
              await fetchImage(file_temp);
              setImages((prev) => {
                let imgs = [];
                if (prev.length < 10) {
                  for (let i = 0; i < prev.length; i++) {
                    imgs.push(prev[i]);
                  }
                  imgs.push(file_temp);
                } else {
                  const tempImages = prev.filter((image, index) => index < 9);
                  imgs.push(file_temp);
                  for (let i = 0; i < tempImages.length; i++) {
                    imgs.push(tempImages[i]);
                  }
                }
                return imgs;
              });
            }
          };
        } else if (extension == 'application/xlsx') {
          event.preventDefault();
        }
      }
    },
    [profile]
  );

  const uploadDocument = async (base64Data, file_name) => {
    const result = await HasuraServices.instance.mutation({
      updateMutation: `mutation MyMutation{
        uploadDocument(input: {base64str:"${base64Data.split(',')[1]}",file_name:"${file_name}"}){
          file_path  
        }
      }`,
      data: {},
    });
    if (result.msg) {
      return result.msg.uploadDocument.file_path;
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `You have exceeded your subscription's maximum limit of 500 pages.`,
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
      return '';
    }
  };

  const imageChange = useCallback(
    (event) => {
      let file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          let filePath = await uploadDocument(reader.result, file.name);
          if (filePath) {
            const file_temp = convertUrl(filePath);
            await fetchImage(file_temp);
            setImages((prev) => {
              let imgs = [];
              if (prev.length < 10) {
                for (let i = 0; i < prev.length; i++) {
                  imgs.push(prev[i]);
                }
                imgs.push(file_temp);
              } else {
                const tempImages = prev.filter((image, index) => index < 9);
                imgs.push(file_temp);
                for (let i = 0; i < tempImages.length; i++) {
                  imgs.push(tempImages[i]);
                }
              }
              return imgs;
            });
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [images]
  );

  async function importImages() {
    // this function will be used to import chat history from database
    const doc = `query ImportImages($where: document_upload_bool_exp!) {
      document_upload(where: $where, order_by: {created_at: desc}, limit: 5) {
        url_name
      }
    }`;

    const variables = {
      where: {
        user_id: {
          _eq: profile.user.id,
        },
        status: {
          _eq: 'inserted',
        },
      },
    };

    const res = await HasuraServices.instance.query(doc, variables);

    let uploadedDocuments = [];

    res.msg?.document_upload?.forEach((document, index) => {
      if (index < 10) {
        uploadedDocuments.push(document.url_name);
      } else {
        deleteUploadedDocument(document.url_name);
      }
    });

    setImages(uploadedDocuments);
  }

  async function importChatHistory() {
    // this function will be used to import chat history from database
    setOffset(0);
    setLimit(2);

    setOldResponses([]);
  }

  function _concatenateArrays(array1, array2) {
    // Create a new array to hold the combined elements
    const combinedArray = [];

    // Add elements from the second array to the combined array
    combinedArray.push(...array2);

    // Add elements from the first array to the combined array
    combinedArray.push(...array1);

    return combinedArray;
  }

  async function importFavouriteHistory() {
    // this function will be used to import chat history from database
    const doc = `query MyQuery($user_id:uuid!){
      chat_history(order_by:{created_at:desc},where:{user_id:{_eq:$user_id},fav:{_eq: true}}){
        images{
          image
        }
        query
        response
        id
        fav
        created_at
        card_id
      }
    }`;
    const variables = {
      user_id: getUserIdFromToken(
        LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)
      ),
    };
    const res = await HasuraServices.instance.query(doc, variables);

    const history = res.msg?.chat_history?.map((chat) => {
      return {
        query: chat.query,
        response: chat.response,
        images: chat.images,
        id: chat.id,
        fav: chat.fav,
        chatDate: chat.created_at,
        cardName: chat.card_id,
      };
    });
    if (history?.length > 0) {
      const refinedHistory = history.map((chatBlock) => {
        chatBlock.images = chatBlock.images.map((img) => {
          return {
            image: img.image.includes('/')
              ? img.image
              : backend_file_url +
                LocalStorageService.instance.get(
                  LocalStorageServiceItems.ACCESS_TOKEN
                ) +
                '/' +
                profile.user.id +
                '/' +
                img.image,
          };
        });
        return chatBlock;
      });
      setOldFavouriteResponses(refinedHistory);
    } else {
      setOldFavouriteResponses([]);
    }
  }

  async function removeFromFavourite(chat_id) {
    // this function will be used to import chat history from database
    const mutation = `mutation MyMutation($chat_id:uuid!,$fav:Boolean!){
      update_chat_history(where:{id:{_eq: $chat_id}},_set:{fav:$fav}){
        affected_rows
      }
    }`;

    const variables = {
      chat_id: chat_id,
      fav: false,
    };

    await HasuraServices.instance.query(mutation, variables);
    setOldResponses((prevResponses) => {
      return prevResponses.map((prevResponse) => {
        if (chat_id == prevResponse.id) return { ...prevResponse, fav: false };
        return prevResponse;
      });
    });
    await importFavouriteHistory();
  }

  async function addToFavourite(chat_id) {
    // this function will be used to import chat history from database
    const mutation = `mutation MyMutation($chat_id:uuid!,$fav:Boolean!){
      update_chat_history(where:{id:{_eq: $chat_id}},_set:{fav:$fav}){
        affected_rows
      }
    }`;

    const variables = {
      chat_id: chat_id,
      fav: true,
    };

    await HasuraServices.instance.query(mutation, variables);
    setOldResponses((prevResponses) => {
      return prevResponses.map((prevResponse) => {
        if (chat_id == prevResponse.id) return { ...prevResponse, fav: true };
        return prevResponse;
      });
    });
    await importFavouriteHistory();
  }

  useEffect(() => {
    oldResponses.forEach((oldResponse) => {
      oldResponse.images.forEach((image) => {
        if (
          String(image.image).split('.').length > 1 &&
          ['.png', '.jpg', '.jpeg'].includes(
            String(image.image).split('.')[
              String(image.image).split('.').length - 1
            ]
          )
        ) {
          if (!(image.image in loadedImages)) fetchImage(image.image);
        }
      });
    });

    async function getChatHistoryChunk() {
      const doc = `query MyQuery($limit: Int!,$offset: Int!,$user_id:uuid!) {
        chat_history(order_by: {created_at: desc}, where: {user_id:{_eq:$user_id} ,chat_status: {_eq: visible}, _and: [{response: {_neq: ""}}, {response: {_neq: "\\nWARNING: contains images\\n"}}]}, limit: $limit, offset: $offset) {
          images {
            image
          }
          card_id
          query
          response
          id
          fav
          created_at
        }
      }
      `;

      let variables = {
        offset: offset,
        limit: limit,
        user_id: getUserIdFromToken(
          LocalStorageService.instance.get(
            LocalStorageServiceItems.ACCESS_TOKEN
          )
        ),
      };
      if (!variables.user_id) {
        clearData();
        return;
      }

      const res = await HasuraServices.instance.query(doc, variables);

      const history = res?.msg?.chat_history?.map((chat) => {
        return {
          query: chat.query,
          response: chat.response,
          images: chat.images,
          id: chat.id,
          fav: chat.fav,
          chatDate: chat.created_at,
          cardName: chat.card_id,
        };
      });
      if (history?.length > 0) {
        const refinedHistory = history.map((chatBlock) => {
          chatBlock.images = chatBlock.images.map((img) => {
            return {
              image: img.image.includes('/')
                ? img.image
                : backend_file_url +
                  LocalStorageService.instance.get(
                    LocalStorageServiceItems.ACCESS_TOKEN
                  ) +
                  '/' +
                  profile?.user?.id +
                  '/' +
                  img.image,
            };
          });
          return chatBlock;
        });
        setOldResponses((prev) => {
          return [
            ...prev.filter(
              (collectedOldResponse) =>
                collectedOldResponse.response != 'how can I help you?'
            ),
            ...refinedHistory,
          ];
        });
        setOffset((prev) => prev + 2);
      } else {
        setOffset((prev) => prev + 50);
        setChatHistoryLoading(false);
      }
    }

    clearTimeout(importDebounceTimeout);
    setImportDebounceTimeout(
      setTimeout(async () => await getChatHistoryChunk(), 500)
    );
  }, [oldResponses]);

  useEffect(() => {
    if (profile?.acc_type) {
      importChatHistory();
      importFavouriteHistory();
      importImages();
    }
  }, [profile]);

  useEffect(() => {
    const handleCopy = () => {
      // Access the copied text from the event
      const copiedText = window.getSelection().toString();
      window.navigator.clipboard.writeText(copiedText);
      setCopiedContent(copiedText);
    };

    document.addEventListener('copy', handleCopy);

    if (isChatPage) window.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('copy', handleCopy);
      window.removeEventListener('paste', handlePaste);
    };
  }, [label]);

  const deleteUploadedDocument = async (url) => {
    // this function will be used to import chat history from database
    const doc = `mutation MyMutationa{
      uploadedDocumentDelete(input:{file_name:"${convertUrl(url)}",user_id:"${profile.user.id}"}){
        message
      }
    }`;
    await HasuraServices.instance.query(doc);
  };

  function _isUrl(str) {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const sendChatQuery = async () => {
    const compressedImages = [];
    for (let i = 0; i < images.length; i++) {
      if (String(images[i]).includes(['jpg', 'jpeg', 'png'])) {
        const img = await handleBlob(images[i]);
        compressedImages.push(img);
      } else {
        compressedImages.push(images[i]);
      }
    }
    if (query || images.length > 0) {
      let _doc;
      let args;
      const modifiedQuery = query.split('\n').join('\\n').split('"').join("'");

      if (images.length > 0) {
        args = {
          query: String(modifiedQuery),

          documents: images.map((img) => img),

          request_type: checked ? 'power_normal' : 'normal',

          store_model_name: storeModelName,
        };
      } else {
        args = {
          query: String(modifiedQuery),
          request_type: checked ? 'power_normal' : 'normal',
          store_model_name: storeModelName,
        };
      }
      //Send message to the websocket connection
      await sendMessage({ args: args });

      clearTimeout(debounceTimeout);
      setDebounceTimeout(setTimeout(() => setChatLoading(false), 59000));
    }
  };

  // async function _addToHistory() {
  //   const modifiedQuery = prevQuery
  //     .split('\n')
  //     .join('\\n')
  //     .split('"')
  //     .join("'");
  //   const resp =
  //     receivedMessage.response ==
  //     '429 Quota exceeded for aiplatform.googleapis.com/generate_content_requests_per_minute_per_project_per_base_model with base model: gemini-1.5-pro. Please submit a quota increase request. https://cloud.google.com/vertex-ai/docs/generative-ai/quotas-genai.'
  //       ? 'Something went wrong. For more information, email us at contact@nexushorizon.fr'
  //       : receivedMessage.response;
  //   const modifiedResponse = resp.split('\n').join('\\n').split('"').join("'");

  //   let history_images = [];
  //   if (images.length > 0) {
  //     for (let i = 0; i < images.length; i++) {
  //       const img_link = await uploadFile(images[i]);
  //       console.log(img_link);
  //       history_images.push(img_link);
  //     }
  //   }
  //   console.log(history_images);
  //   const doc = `mutation MyMutation{
  //     insert_chat_history_one(object:{query:"${modifiedQuery}",response:"${modifiedResponse}"${history_images.length > 0 && `images: {data: [${history_images.map((img_link) => `{image: "${img_link}"}`)}]}`}}){
  //       query
  //       response
  //       images{
  //         image
  //       }
  //     }
  //   }`;
  //   const res = await HasuraServices.instance.query(doc, {});
  //   if (res.status) {
  //     console.log(res);
  //     setChatLoading(false);
  //   }
  // }

  useEffect(() => {
    if (receivedMessage.response == 'restarted chatbot successfully') {
      setRestarting(false);
      clearTimeout(restartTimeout);
      importChatHistory();
    } else if (receivedMessage.response) {
      clearTimeout(debounceTimeout);

      if (receivedMessage.status == 'Intermediate') {
        setChatLoading(true);
        setDebounceTimeout(setTimeout(() => setChatLoading(false), 120000));
      } else {
        setChatLoading(false);
      }
      if (receivedMessage.response != 'knock knock from the backend') {
        setOldResponses((prev) => {
          // flag that lets  us know if data has already been added to the response
          let flag = false;

          let res = receivedMessage.response;
          let returningArray = [];
          let reqNum = 0;
          for (let i = 0; i < prev.length; i++) {
            if (
              res ==
                '429 Quota exceeded for aiplatform.googleapis.com/generate_content_requests_per_minute_per_project_per_base_model with base model: gemini-1.5-pro. Please submit a quota increase request. https://cloud.google.com/vertex-ai/docs/generative-ai/quotas-genai.' ||
              res == '500 Internal error encountered.' ||
              res == 'expected string or bytes-like object' ||
              String(res).includes('[Errno 2] No such file or directory') ||
              res == 'list index out of range'
            ) {
              res =
                'Something went wrong. For more information, email us at contact@nexushorizon.fr';
            }
            if (prev[i].id == receivedMessage.chat_id) {
              returningArray[i] = {
                ...prev[i],
                query: prev[i].query,
                response: res,
                images: prev[i].images,
              };
              flag = true;
            }
            returningArray.push(prev[i]);
          }
          if (!flag) {
            returningArray[reqNum] = {
              ...prev[reqNum],
              query: prev[reqNum].query,
              response: res,
              images: prev[reqNum].images,
              id: receivedMessage.chat_id,
            };
          }
          return returningArray;
        });
      } else {
        setOldResponses((prev) => {
          // flag that lets us know if data has already been added to the response
          let flag = false;
          let res_chat_id = receivedMessage.chat_id;
          let returningArray = [];
          let reqNum = 0;
          for (let i = 0; i < prev.length; i++) {
            if (prev[i].chat_id == '') {
              reqNum = i;
            } else {
              flag = true;
              returningArray[reqNum] = {
                query: prev[reqNum].query,
                chat_id: res_chat_id,
                images: prev[reqNum].images,
              };
            }
            returningArray.push(prev[i]);
          }
          if (!flag) {
            returningArray[reqNum] = {
              query: prev[reqNum].query,
              chat_id: res_chat_id,
              images: prev[reqNum].images,
            };
          }

          return returningArray;
        });
      }
    }
  }, [receivedMessage]);

  function checkKeyInObject(obj, key) {
    return key in obj;
  }

  const fetchImage = async (imageUrl) => {
    try {
      if (
        checkKeyInObject(
          loadedImages,
          isValidHttpUrl(imageUrl)
            ? imageUrl
            : `${backend_file_url}${LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)}/${profile.user.id}/${imageUrl.split('/')[imageUrl.split('/').length - 1]}`
        )
      ) {
        const response = await axios.get(
          isValidHttpUrl(imageUrl)
            ? imageUrl
            : `${backend_file_url}${LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)}/${profile.user.id}/${imageUrl.split('/')[imageUrl.split('/').length - 1]}`,
          { responseType: 'blob' }
        );
        const blob = response.data;
        const imageURL = URL.createObjectURL(blob);
        setLoadedImages((prev) => {
          prev[imageUrl] = imageURL;
          return prev;
        });
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  useEffect(() => {
    if (!isWebsocketON) {
      setOldResponses((prev) => {
        return prev.map((item) => {
          if (item.response == '') {
            item.response =
              'Something went wrong. For more information, email us at contact@nexushorizon.fr';
          }
          return item;
        });
      });
      setChatLoading(false);
    }
  }, [isWebsocketON]);
  function normalizeUrl(url) {
    try {
      // Attempt to create a new URL object (handles basic URL validation)
      const parsedUrl = new URL(url);
      return parsedUrl.toString(); // Return the normalized URL string
    } catch (error) {
      // If URL parsing fails, try decoding directly
      return decodeURIComponent(url).replace(/\+/g, ' '); // Replace '+' with spaces for compatibility
    }
  }

  return (
    <ChatContext.Provider
      value={{
        query,
        normalizeUrl,
        setQuery,
        modelName,
        setModalName,
        storeModelName,
        setStoreModelName,
        sendChatQuery,
        chatLoading,
        images,
        copiedContent,
        copyToClipboard,
        setImages,
        handleUpload,
        handleRestart,
        handleSearch,
        handleMoreDetails,
        oldResponses,
        setOldResponses,
        oldFavouriteResponses,
        setOldFavouriteResponses,
        importFavouriteHistory,
        imageChange,
        handlePaste,
        imageRef,
        imageChangeFromDropZone,
        restarting,
        loadedImages,
        clearData,
        deleteUploadedDocument,
        fetchImage,
        addToFavourite,
        removeFromFavourite,
        isFavouritePage,
        setIsFavouritePage,
        chatHistoryLoading,
        isChatPage,
        setIsChatPage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
